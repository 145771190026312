/* global SHEIN_BACKUP_ONETRUST,OptanonActiveGroups */
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { sendLogs, formatLogParams, checkCookieStatus } from './util'
import { checkStorage } from '../cookie_banner/utils'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import mitt from 'mitt'
import { setLocalStorage, getLocalStorage } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '2-35' })

import { POP_UP_MONITOR } from 'public/src/pages/common/glob_popup_analysis.js'

let isBackupOnetrustLoaded = false
const { ONE_TRUST_SITES_SUPPORT = [], CUSTOM_PRIVACY_SUPPORT = [], SiteUID } = gbCommonInfo
// pageId=1311687728
const exposeDqOnetrustEndParams = {
  dialog_name: 'onetrust',
  remark: 'onetrust弹窗未启动',
  event_name: 'expose_dq_onetrust_end'
}
function isShowOneTrustPopup(queue_x, queue_y) {
  if (queue_x == undefined || queue_y == undefined) {
    return false
  }

  let oneTrustPopupShow = getLocalStorage('onetrust_popup_show')

  let oneTrustPopupCount = parseInt(getLocalStorage('onetrust_popup_count')) || 0

  // 检查是否是第一次弹窗
  if (!oneTrustPopupShow) {
    oneTrustPopupCount = 1
    setLocalStorage({
      key: 'onetrust_popup_show',
      value: '1',
      expire: queue_x * 24 * 60 * 60 * 1000 // 设置过期时间为间隔x天后
    })

    setLocalStorage({
      key: 'onetrust_popup_count',
      value: oneTrustPopupCount.toString()
    })

    return true
  } else {
    // 如果不是第一次弹窗，则判断是否需要继续弹窗
    if (oneTrustPopupCount < queue_y) {
      oneTrustPopupCount++

      setLocalStorage({
        key: 'onetrust_popup_count',
        value: oneTrustPopupCount.toString()
      })

      return true
    }

    return false
  }
}

async function setOneTrustDisplay(abtInfo = null) {
  let HomePopup = {}
  if (abtInfo) {
    // 减少abt请求次数
    HomePopup = abtInfo
  } else {
    HomePopup = await getUserAbtData()
    HomePopup = HomePopup?.HomePopup || {}
  }
  const { onetrust_pc_queue, onetrust_pc_queue_x, onetrust_pc_queue_y, onetrust_pc_style } = HomePopup?.param || {}
  try {
    // 非开放站点
    if (!ONE_TRUST_SITES_SUPPORT.includes(SiteUID)) {
      if (onetrust_pc_queue == '1') {
        POP_UP_MONITOR.operationMonitor(exposeDqOnetrustEndParams)
      }
      return
    }

    const { page_name } = window.getSaPageInfo || window.SaPageInfo || {}
    const supportPage = ['page_home', 'page_goods_detail', 'page_category', 'page_daily_new', 'page_real_class', 'page_select_class', 'page_search', 'page_goods_group']

    if (supportPage.includes(page_name)) {
      // 降级模式
      if (isBackupOnetrustLoaded) {
        if (onetrust_pc_queue == '1') {
          POP_UP_MONITOR.operationMonitor(exposeDqOnetrustEndParams)
        }
        SHEIN_BACKUP_ONETRUST.show()
        return
      }
      const onetrustBannerEle = document.getElementById('onetrust-banner-sdk')
      if (onetrustBannerEle) {
        if (onetrust_pc_queue == '1') {
          POP_UP_MONITOR.operationMonitor({
            dialog_name: 'onetrust',
            remark: 'onetrust弹窗启动',
            event_name: 'expose_dq_onetrust_start'
          })
          window._GB_PopUpModalEventCenter_.pushQueue('onetrust', () => {
            if (isShowOneTrustPopup(onetrust_pc_queue_x, onetrust_pc_queue_y)) {
              // 创建遮罩元素
              const overlay = document.createElement('div')
              overlay.classList.add('onetrust-banner-overlay')
              document.getElementById('onetrust-consent-sdk').appendChild(overlay)

              // 禁止页面滚动
              document.body.classList.add('onetrust-overlay-parent__hidden')
              onetrustBannerEle.classList.remove('she-none')

              window.OneTrust?.OnConsentChanged(() => {
                window._GB_PopUpModalEventCenter_.next('onetrust')
                document.body.classList.remove('onetrust-overlay-parent__hidden')
                overlay.remove()
              })

              daEventCenter.triggerNotice({ daId: '2-35-1' })
            } else {
              window._GB_PopUpModalEventCenter_.next('onetrust')
            }
          })
        } else {
          onetrustBannerEle.classList.remove('she-none')
          window._GB_PopUpModalEventCenter_.done('onetrust')
          daEventCenter.triggerNotice({ daId: '2-35-1' })
        }

        if (onetrust_pc_style == '1') {
          onetrustBannerEle.classList.add('onetrust-custom-style')
        }
      } else {
        if (onetrust_pc_queue == '1') {
          POP_UP_MONITOR.operationMonitor(exposeDqOnetrustEndParams)
        }
        window._GB_PopUpModalEventCenter_.done('onetrust')
      }

      modifyPrivacyLink()
    } else {
      if (onetrust_pc_queue == '1') {
        POP_UP_MONITOR.operationMonitor(exposeDqOnetrustEndParams)
      }
      window._GB_PopUpModalEventCenter_.done('onetrust')
    }

  } catch (e) {
    if (onetrust_pc_queue == '1') {
      POP_UP_MONITOR.operationMonitor(exposeDqOnetrustEndParams)
    }
    window._GB_PopUpModalEventCenter_.done('onetrust')
    // empty 
  }
}

// 因onetrust配置后台无法根据站点来配置隐私政策链接、需要手动修改链接形式
function modifyPrivacyLink() {
  const { langPath } = gbCommonInfo
  const $el = document.getElementById('onetrust-policy-text')?.getElementsByTagName('a')[0]
  const val = $el?.getAttribute('href')
  if (!val) return
  const avalue = document.createElement('a')
  avalue.href = val
  const link = `${langPath}${avalue.pathname}`
  $el.setAttribute('href', link)
}

function InitBackUpOnetrustPop() {
  if (isBackupOnetrustLoaded) return
  isBackupOnetrustLoaded = true
  import(/* webpackChunkName: "backup-onetrust" */'./backup').then(() => {
    SHEIN_BACKUP_ONETRUST.init(setOneTrustDisplay)
    if (!window.OneTrust) window.OneTrust = {}
    window.OneTrust.ToggleInfoDisplay = SHEIN_BACKUP_ONETRUST.settings
  })
}

function syncLocalLogs() {
  const memeberId = UserInfoManager.get({ key: 'memberId', actionType: 'syncLocalLogs' }) || ''
  let cache = (localStorage.getItem('SyscLocalOnetrustLogCache') || '').split(',')

  if (!memeberId || cache.length == 0 || typeof OptanonActiveGroups === 'undefined') return
  // 已推送
  if (cache.includes(memeberId)) return
  // 未点击过
  if (!window?.OneTrust?.IsAlertBoxClosed()) return

  if (cache.length >= 50) cache.shift()
  cache.push(memeberId)
  localStorage.setItem('SyscLocalOnetrustLogCache', cache.join(','))
  sendLogs(formatLogParams(OptanonActiveGroups.split(',')))
}

// 特定路径展示 one trust cookie 弹窗
export async function initOneTrusPathDisplay() {
  if (CUSTOM_PRIVACY_SUPPORT.includes(SiteUID)) {
    window._GB_PopUpModalEventCenter_.done('onetrust')
    return
  }

  const { HomePopup } = await getUserAbtData()
  let { onetrust_pc_queue } = HomePopup?.param || {}
  onetrust_pc_queue = 1
  if (!window.OneTrust || !document.getElementById('onetrust-banner-sdk')) {
    if (onetrust_pc_queue == '1') {
      POP_UP_MONITOR.operationMonitor(exposeDqOnetrustEndParams)
    }
    window._GB_PopUpModalEventCenter_.done('onetrust')
  }

  // backup 降级弹窗展示
  if (window.onetrustBannerLoadedError) InitBackUpOnetrustPop()
  window.addEventListener('onetrustBannerLoadedError', () => InitBackUpOnetrustPop())

  if (window.isOnetrustBannerLoaded) {
    setOneTrustDisplay(HomePopup)
  }

  if (!window.appEventCenter) {
    window.oneTrustEventCenter = mitt()
  }

  window.addEventListener('onetrustBannerLoaded', () => {
    setOneTrustDisplay(HomePopup)
    window.OneTrust?.OnConsentChanged((v) => {
      checkCookieStatus(v.detail || ['C0001'])
      checkStorage(v.detail || [], 'agree')
      window?.oneTrustEventCenter?.emit('onetrustSettingDetail', v)
      sendLogs(formatLogParams(v.detail || []))
    })
    syncLocalLogs()
  })

  const activeGroups = window.OptanonActiveGroups?.split(',')
  window.OptanonActiveGroups && checkCookieStatus(activeGroups || ['C0001'])
  
  checkStorage(activeGroups || [], 'agree')
}

export function reloadOneTrustEvents() {
  const isshow = window.OneTrust && !window.OneTrust.IsAlertBoxClosed() || false
  if (!isshow) return
  setOneTrustDisplay()
}
